import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'
import useCommunitySearch, {
  SearchCommunityType,
} from 'Features/CommunitySearch/useCommunitySearch'
import Utils from 'Utils'

import {
  Avatar,
  Column,
  Loader,
  Modal,
  Row,
  SearchInput,
  Text,
  Tooltip,
} from 'Components/UI'

import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import MergeProfileItem from './Components/MergeProfileItem'
import recommendDuplicatesQuery from './Queries/recommendDuplicatesQuery.graphql'
import * as Styled from './styles'

interface MergeProfilesModalProps {
  isOpen: boolean
  communityUser?: MainSchema.CommunityUser
  onClose: () => void
}

// TODO: setup for demo purposes - waiting for backend
const MergeProfilesModal = ({
  isOpen,
  communityUser,
  onClose,
}: MergeProfilesModalProps) => {
  const t = useScopedI18n('features.mergeProfiles')
  const { communityId, id, photoUrl } = communityUser || {}
  const communitySearch = useCommunitySearch()

  const [currentStep, setCurrentStep] = useState<1 | 2>(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState<
    MainSchema.CommunityUser[]
  >([])
  const [isSearching, setIsSearching] = useState(false)
  const [selectedToMerge, setSelectedToMerge] = useState<
    MainSchema.CommunityUser[]
  >([])

  const {
    data: recommendedDuplicatesData,
    loading: isRecommendedDuplicatesLoading,
  } = useQuery<
    Pick<MainSchema.Query, 'recommendDuplicates'>,
    MainSchema.QueryRecommendDuplicatesArgs
  >(recommendDuplicatesQuery, {
    skip: !communityId && !id,
    variables: { communityId: communityId!, communityUserId: id! },
    fetchPolicy: 'network-only',
  })

  const recommendedDuplicates = recommendedDuplicatesData?.recommendDuplicates
  const profileAnchorName = useMemo(() => {
    return Utils.User.getFullName(communityUser)
  }, [communityUser])

  const handleSearch = useCallback(
    async (value: string) => {
      setSearchQuery(value)

      if (value.length < 3) {
        setSearchResults([])
        return
      }

      setIsSearching(true)

      try {
        const limit = 10
        const results = await communitySearch.searchCommunity({
          communityIds: [communityId!],
          searchText: value,
          types: [
            SearchCommunityType.SemanticUsers,
            SearchCommunityType.DirectUsers,
          ],
          limit,
        })
        const { combinedUserResults } = communitySearch.processUsers(
          results[SearchCommunityType.DirectUsers].data
            .directSearchCommunityUsers.results,
          results[SearchCommunityType.SemanticUsers].data
            .semanticSearchCommunityUsers.results,
          limit,
        )

        const people = combinedUserResults
          .filter(cu => cu.node.id !== id)
          .map(cu => cu.node) as MainSchema.CommunityUser[]

        setSearchResults(people)
      } catch (error: any) {
        toast.error({
          title: _('error.generic'),
          text: _('error.server.message', { message: error.message }),
        })
      } finally {
        setIsSearching(false)
      }
    },
    [communitySearch, communityId, id],
  )

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      setSearchQuery(value)
      handleSearch(value)
    },
    [handleSearch],
  )

  const handleAddToMerge = useCallback(
    (communityUser: MainSchema.CommunityUser) => {
      setSelectedToMerge(prev =>
        prev.some(item => item.id === communityUser.id)
          ? prev.filter(item => item.id !== communityUser.id)
          : [...prev, communityUser],
      )
    },
    [],
  )

  const handleRemoveFromMerge = useCallback(
    (communityUser: MainSchema.CommunityUser) => {
      setSelectedToMerge(prev =>
        prev.some(item => item.id === communityUser.id)
          ? prev.filter(item => item.id !== communityUser.id)
          : prev,
      )
    },
    [],
  )

  const handleOnClose = useCallback(() => {
    setSelectedToMerge([])
    setSearchResults([])
    setSearchQuery('')
    setCurrentStep(1)
    onClose()
  }, [onClose])

  const handleBack = useCallback(() => {
    setCurrentStep(1)
  }, [])

  const handleMergeSelected = useCallback(() => {
    if (currentStep === 1) {
      setCurrentStep(2)
    } else {
      // TODO: merge logic
      handleOnClose()
    }
  }, [handleOnClose, currentStep])

  const renderSearchResults = () => {
    if (isSearching) {
      return <Loader />
    }

    if (searchResults.length > 0) {
      return searchResults.map(cu => (
        <MergeProfileItem
          communityUser={cu}
          handleAddToMerge={handleAddToMerge}
          key={cu.id}
          selectedToMerge={selectedToMerge}
        />
      ))
    }

    if (searchQuery) {
      return (
        <Text bodyMedium color="text.body" p={3}>
          {t('noSearchResults')}
        </Text>
      )
    }

    return null
  }

  const renderRecommendedDuplicatesList = () => {
    if (isRecommendedDuplicatesLoading) {
      return <Loader />
    }

    if (!recommendedDuplicates?.length) {
      return (
        <Text bodyMedium color="text.body" mb={2}>
          {t('noSuggestedMatchesFound')}
        </Text>
      )
    }

    return recommendedDuplicates?.map(({ communityUser }) => (
      <MergeProfileItem
        communityUser={communityUser}
        handleAddToMerge={handleAddToMerge}
        key={communityUser?.id}
        selectedToMerge={selectedToMerge}
      />
    ))
  }

  const renderSelectedProfiles = () => (
    <Row gap={2} wrapped>
      {communityUser && (
        <Styled.ProfileTokenItem isAnchor>
          <Avatar extraSmall src={photoUrl} />
          <Text>{profileAnchorName}</Text>
        </Styled.ProfileTokenItem>
      )}
      {selectedToMerge.map(communityUser => {
        const profile = selectedToMerge?.find(cu => cu.id === communityUser.id)
        return (
          <Tooltip
            content={
              profile?.email ||
              profile?.phoneNumber ||
              profile?.linkedInUrl ||
              profile?.facebookUrl ||
              profile?.twitterUrl
            }
          >
            <Styled.ProfileTokenItem
              onClick={() => handleRemoveFromMerge(communityUser)}
            >
              <Avatar extraSmall src={profile?.photoUrl} />
              <Text>{Utils.User.getFullName(profile)}</Text>
              <Styled.ProfileTokenClose size={16} />
            </Styled.ProfileTokenItem>
          </Tooltip>
        )
      })}
    </Row>
  )

  return (
    <Modal
      cancelText={currentStep === 1 ? t('cancel') : t('back')}
      confirmDisabled={currentStep === 1 ? !selectedToMerge.length : false}
      confirmText={currentStep === 1 ? t('mergeSelected') : t('confirm')}
      glyphIcon={<Styled.MergeIcon />}
      isOpen={isOpen}
      width="400px"
      onClose={currentStep === 1 ? handleOnClose : handleBack}
      onConfirm={handleMergeSelected}
    >
      {currentStep === 1 ? (
        <Column gap={3}>
          <Text header2>{t('title')}</Text>
          <Text bodyMedium color="text.body" mb={2}>
            {t('subtitle')}
          </Text>

          {renderSelectedProfiles()}

          <Styled.Divider />

          <Styled.SearchWrapper>
            <SearchInput
              placeholder={t('searchPlaceholder')}
              value={searchQuery}
              onChange={handleSearchChange}
              onClear={() => {
                setSearchQuery('')
                setSearchResults([])
              }}
            />
            {searchQuery && (
              <Styled.DropdownContainer>
                <Text bodySmall color="text.body" p={2}>
                  {t('searchResults')}
                </Text>
                <Styled.ScrollContainer>
                  {renderSearchResults()}
                </Styled.ScrollContainer>
              </Styled.DropdownContainer>
            )}
          </Styled.SearchWrapper>

          <Text header3 mt={3}>
            {t('suggestedTitle')}
          </Text>

          <Styled.ScrollContainer>
            {renderRecommendedDuplicatesList()}
          </Styled.ScrollContainer>
        </Column>
      ) : (
        currentStep === 2 && (
          <Column gap={3}>
            <Text header2>{t('confirmMergeTitle')}</Text>
            <Text bodyMedium color="text.body">
              {t('confirmMergeDescription')}
            </Text>
          </Column>
        )
      )}
    </Modal>
  )
}

export default MergeProfilesModal
